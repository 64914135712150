import React, {useState, useEffect, useMemo } from 'react';
import { useParams  } from 'react-router-dom';
import FsLightbox from "fslightbox-react";
import Map, {Marker} from 'react-map-gl';

import Pin from './Pin';

function Psingle() {
    const [lightboxController, setLightboxController] = useState({
		toggler: false,
		slide: 1
	});

	function openLightboxOnSlide(number) {
		setLightboxController({
			toggler: !lightboxController.toggler,
			slide: number
		});
	}

    const { id } = useParams();

    const [projects, setProjects] = useState([]);
    useEffect(() => {
       fetch(`https://admin.peam.group/api/projects/${id}`)
          .then((response) => response.json())
          .then((data) => {
             setProjects(data);
          })
    }, []);


    if (!projects.longitude || !projects.latitude) {
        return <div>Loading...</div>; // or some other loading indicator
      }



    return <div>
        <div>
        <div id="hero">
        <div className="hero-fadeout"></div>
        <div className="hero-overlay"></div>
        <div className="hero-img" style={{ backgroundImage: `url(${'https://peam.s3.amazonaws.com/' + projects.ftimage})` }}>
            <div className="section-title-projects">
                <p>{projects.category}</p>
                <h2>{projects.title}</h2>
                <h6>{projects.address}</h6>
            </div>
        </div>
        </div>

        <div className="trabajos-single">
            <div className="project-breadcrumbs">
                <a href="/#/projects">Todos los trabajos realizados</a>
            </div>

            <div className="project-info">
                <div className="project-img">
                    <img src={'https://peam.s3.amazonaws.com/' + projects.ftimage} />
                </div>

                <div className="project-description">
                    <div className="description-row">
                        <div className="description-column">
                            <h2>Insignia</h2>
                            <h3>{projects.insignia}</h3>

                            <h2>Producto</h2>
                            <h3>{projects.product}</h3>
                        </div>

                        <div className="description-column">
                            <h2>Tipo de desarrollo</h2>
                            <h3>{projects.type}</h3>

                            <h2>Estatus de trabajo</h2>
                            <h3>{projects.status}</h3>
                        </div>
                    </div>

                    <div className="description-row">
                        <div className="description-column">
                            <h2>Overview</h2>
                            <h3 dangerouslySetInnerHTML={{ __html:projects.overview }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="project-map">
            <>
    
            <Map
                mapboxAccessToken="pk.eyJ1IjoibGVpbGFhdCIsImEiOiJjbGoxNTc3azIwMHI5M2dvZWFhbGtxaTA4In0.fhfdwfPhi3pDKirjbm_PeA"
                initialViewState={{
                longitude: projects.longitude,
                latitude: projects.latitude,
                zoom: 7
                }}
                mapStyle="mapbox://styles/mapbox/dark-v9"
            >
                
                <Marker 
                    longitude={projects.longitude}
                    latitude={projects.latitude}
                    anchor="bottom"
                >
                {projects.category === 'Comercial' && <Pin size={20} fill="#b2eb39" />} {/* Green */}
                {projects.category === 'Residencial' && <Pin size={20} fill="#4862bc" />} {/* Blue */}
                {projects.category === 'Industrial' && <Pin size={20} fill="#ebaf21" />} {/* Yellow */}
                </Marker>
                
            </Map>
            </>
            </div>

            <div className="project-gallery">
                <div className="img-fullwidth">
                    <img src={'https://peam.s3.amazonaws.com/' + projects.images} onClick={() => openLightboxOnSlide(1)}/>
                </div>

                <div className="three-imgs">
                    <div className="three-imgs-div">
                        <img src={'https://peam.s3.amazonaws.com/' + projects.imagetwo } onClick={() => openLightboxOnSlide(2)}/>
                    </div>

                    <div className="three-imgs-div">
                        <img src={'https://peam.s3.amazonaws.com/' + projects.imagethree } onClick={() => openLightboxOnSlide(3)}/>
                    </div>

                    <div className="three-imgs-div">
                        <img src={'https://peam.s3.amazonaws.com/' + projects.imagefour } onClick={() => openLightboxOnSlide(4)}/>
                    </div>
                </div>

                <div className="img-fullwidth">
                    <img src={'https://peam.s3.amazonaws.com/' + projects.imagefive } onClick={() => openLightboxOnSlide(5)}/>
                </div>

                <div className="img-fullwidth">
                    <img src={'https://peam.s3.amazonaws.com/' + projects.imagesix } onClick={() => openLightboxOnSlide(6)}/>
                </div>

                <div className="img-fullwidth">
                    <img src={'https://peam.s3.amazonaws.com/' + projects.imageseven } onClick={() => openLightboxOnSlide(7)}/>
                </div>

                <FsLightbox
				toggler={lightboxController.toggler}
				sources={[
					`https://peam.s3.amazonaws.com/${encodeURIComponent(projects.images)}`,
					`https://peam.s3.amazonaws.com/ + ${projects.imagetwo }}`,
                    `https://peam.s3.amazonaws.com/${projects.imagethree}`,
                    `https://peam.s3.amazonaws.com/${projects.imagefour}`,
                    `https://peam.s3.amazonaws.com/${projects.imagefive}`,
                    `https://peam.s3.amazonaws.com/${projects.imagesix}`,
                    `https://peam.s3.amazonaws.com/${projects.imageseven}`,

				]}
				slide={lightboxController.slide}
                key={lightboxController.key}

			/>
            </div>
        </div>
        </div>
    </div>;
}

export default Psingle;