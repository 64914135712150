import React, {useState, useEffect } from 'react';
import { useParams  } from 'react-router-dom';
import { Link } from 'react-router-dom';


function Single() {
    const { id } = useParams();

    const [teams, setTeam] = useState([]);
    useEffect(() => {
       fetch(`https://admin.peam.group/api/team/${id}`)
          .then((response) => response.json())
          .then((data) => {
             setTeam(data);
          })
    }, []);

    return <div>
        <div className="team-single">
        <div className="team-left">
            <div className="team-breadcrumbs">
                <Link to="/Team">Regresa a Meet the Team</Link>
            {/* <a href="/#/Team">Regresa a Meet the Team</a> */}
            </div>
            <div className="team-img">
                <div className="team-img-inner">
                    <div className="img-inner-overlay"></div>
                    <img src={'https://peam.s3.amazonaws.com/' + teams.image} />
                </div>
                <div className="team-socials">
                    <li className={teams.instagram === 'null' ? "socials hidden" : "socials"} ><a href={teams.instagram} target="_blank"><img src="/imgs/instagram.svg" /></a></li>
                    <li className={teams.linkedin === 'null' ? "socials hidden" : "socials"} ><a href={teams.linkedin} target="_blank"><img src="/imgs/linkedin.svg" /></a></li>
                    <li className={teams.facebook === 'null' ? "socials hidden" : "socials"} ><a href={teams.facebook} target="_blank"><img src="/imgs/facebook.svg" /></a></li>
                    <li className={teams.email === 'null' ? "socials hidden" : "socials"} ><a href={teams.email} target="_blank"><img src="/imgs/email.svg" /></a></li>
                </div>
            </div>
            <div className="team-basic-info">
                <h3>{teams.name}</h3>
                <h4>{teams.position}</h4>
            </div>
        </div>

        <div className="team-right">
        <p dangerouslySetInnerHTML={{ __html: teams.desc }} />
        </div>
    </div>
    </div>;
}

export default Single;