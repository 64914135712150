import React, { useState, useEffect } from 'react';
import FilterMap from './Project/Mapfilter';

function Projects() {

    return <div>
        <div id="hero">
            <div className="hero-fadeout"></div>
            <div className="hero-overlay"></div>
            <div className="hero-img hero-img-projects">
                <div className="section-title">
                    <h2>Trabajos realizados</h2>
                </div>
            </div>
        </div>

    <div className="trabajos-map">
        <div className="trabajos-map-inner">
            <FilterMap />
        </div>

        
    </div>

    </div>;
}

export default Projects;