import React, {useState, useEffect } from 'react';
import { useParams  } from 'react-router-dom';

function Job() {
    const { id } = useParams();

    const [careers, setCareer] = useState([]);
    useEffect(() => {
       fetch(`https://admin.peam.group/api/careers/${id}`)
          .then((response) => response.json())
          .then((data) => {
             setCareer(data);
          })
    }, []);

    return <div>
        <div className="job-single">
            <div className="bolsa-job-left">
                <div className="team-breadcrumbs">
                <a href="/#/careers">Regresa a Bolsa de trabajo</a>
                </div>

                <div className="bolsa-job-title">
                    <h3>{careers.title}</h3>
                    <p><span className="job-category">{careers.category}</span> / {careers.location}</p>
                    <p><span className="job-category">Fecha de postulación:</span> {careers.date}</p>
                </div>

                <div className="bolsa-job-text">
                    <p dangerouslySetInnerHTML={{ __html: careers.desc }} />
                </div>

            </div>

            <div className="bolsa-job-right">
                <div className="intro-cta">
                <a href to="/contact"><button>Contáctanos sobre este trabajo</button></a>
                </div>
            </div>
        </div>
    </div>;
}

export default Job;