import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div>
		<footer>
            <div className="footer-top">
                <h2>Conecta con nosotros</h2>
                <div className="footer-contact">
                    <div className="footer-socials">
                        <li><a href="https://www.google.com/" target="_blank" rel="noreferrer" aria-label="Facebook">Facebook</a></li>
                        <li><a href="https://www.google.com/" target="_blank" rel="noreferrer" aria-label="Instagram">Instagram</a></li>
                        <li><a href="https://www.google.com/" target="_blank" rel="noreferrer" aria-label="LinkedIn">LinkedIn</a></li>
                    </div>

                    <div className="footer-socials">
                        <li><Link to="/bolsadetrabajo">Bolsa de trabajo</Link></li>
                        <li><Link to="/faq">F.A.Q</Link></li>
                        <li><Link to="/privacy">Privacidad</Link></li>
                    </div>

                    <div className="footer-contact-info">
                        <div className="footer-contact-inner">
                            <li className="white">Email</li>
                            <li><a href="https://www.google.com/" target="_blank" rel="noreferrer" aria-label="email@peam.com.mx">email@peam.com.mx</a></li>
                        </div>

                        <div className="footer-contact-inner">
                            <li className="white">Teléfono</li>
                            <li><a href="https://www.google.com/" target="_blank" rel="noreferrer" aria-label="+52.442.123.4567">+52.442.123.4567</a></li>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-bottom">
                <div className="footer-copyright">
                    PEAM © 2023. All rights reserved
                </div>

                <div className="footer-logos">
                    <li><a href="https://swapp.today" target="_blank" rel="noreferrer"><img src="/imgs/swapp-logo.svg" alt="SWAPP" /></a></li>
                    <li><a href="https://www.google.com/"><img src="/imgs/vicer_white.png" alt="VICER" /></a></li>
                    <li><a href="https://www.google.com/clea"><img src="/imgs/maison_white.png" alt="Maison Vicer" /></a></li>
                </div>
            </div>
        </footer>
    </div>
  );
};

export default Footer;
