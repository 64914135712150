import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';


const Navbar = () => {
  const [line_menu, setLineMenu] = useState("line_menu")
  const [wrapper_menu, setMiddleMenu] = useState("wrapper_menu")
  const [full_menu, setMenuClass] = useState("menu hidden")
  const [menu_open, setMenuOpen] = useState("menu_open visible")
  const [menu_close, setMenuClose] = useState("menu_close hidden")
  const [isMenuClicked, setIsMenuClicked] = useState(false)

  const updateMenu = () => {
    if(!isMenuClicked) {
      setLineMenu("line_menu open")
      setMenuClass("full_menu visible")
      setMiddleMenu("wrapper_menu open")
      setMenuOpen("menu_open hidden")
      setMenuClose("menu_open visible")
    }
    else {
      setLineMenu("line_menu")
      setMenuClass("full_menu hidden")
      setMiddleMenu("wrapper_menu")
      setMenuOpen("menu_open visible")
      setMenuClose("menu_open hidden")
    }
    setIsMenuClicked(!isMenuClicked)
  }

  const closeMenu = () => {
    setLineMenu("line_menu")
    setMenuClass("full_menu hidden")
    setMiddleMenu("wrapper_menu")
    setMenuOpen("menu_open visible")
    setMenuClose("menu_open hidden")
  }
  

  return (
    <div>
		  <div id="navbar">
        <div className="navbar-left">
          <div className="menu-button" onClick={updateMenu}>

            <div className={wrapper_menu}>
              <div className={line_menu}></div>
              <div className={line_menu}></div>
              <div className={line_menu}></div>
            </div>

            <span className={menu_open}>MENU</span>
            <span className={menu_close}>Cerrar</span>
          </div>

          <div id="logo-scroll">
            <Link to="/" onClick={closeMenu}><img alt="PEAM LOGO" className="logo" id="logo" src="/imgs/logo_white.png" /></Link>
          </div>

        </div>

        <div id="navbar-right">
          <li><NavLink activeclassname="active" to="/services" onClick={closeMenu}>Servicios</NavLink></li>
          <li><NavLink activeclassname="active" to="/projects" onClick={closeMenu}>Trabajos realizados</NavLink></li>
          <li><NavLink activeclassname="active" to="/contact" onClick={closeMenu}>Contacto</NavLink></li>
        </div>
      </div>

      <div className={full_menu}>
        <div className="full-menu-inner">
          <li><Link to="/team/" onClick={updateMenu}>Meet the Team</Link></li>
          <li><Link to="/faq" onClick={updateMenu}>Preguntas Frecuentes</Link></li>
          <li><Link to="/careers" onClick={updateMenu}>Bolsa de trabajo</Link></li>
          <li><Link to="/quote" onClick={updateMenu}>Cotiza tu proyecto</Link></li>
          <li><Link to="/asesoria" onClick={updateMenu}>Asesoría</Link></li>
          <li className="mob-navbar-right"><Link to="/servicios" onClick={updateMenu}>Servicios</Link></li>
          <li className="mob-navbar-right"><Link to="/projects" onClick={updateMenu}>Trabajos realizados</Link></li>
          <li className="mob-navbar-right"><Link to="/contacto" onClick={updateMenu}>Contacto</Link></li>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
