import React from 'react';
import { Accordion, AccordionItem as Item } from '@szhsin/react-accordion';
import { useState, useEffect } from 'react';
import chevronDown from './chevron-down.svg'

function Faq() {

    const [faqs, setfaqs] = useState([]);
    useEffect(() => {
       fetch('https://admin.peam.group/api/faq/index')
          .then((response) => response.json())
          .then((data) => {
             console.log(data);
             setfaqs(data);
          })
          .catch((err) => {
             console.log(err.message);
          });
    }, []);
  
    const [toggleState, setToggleState] = useState(1);
    const toggleTab = (index) => {
      setToggleState(index);
    };

    const AccordionItem = ({ header, ...rest }) => (
        <Item
          {...rest}
          header={
            <>
              {header}
              <img className="chevron"src={chevronDown} alt="Chevron Down" />
            </>
          }
          className="item"
          buttonProps={{
            className: ({ isEnter }) =>
              `itemBtn ${isEnter && "itemBtnExpanded"}`
          }}
          contentProps={{ className: "itemContent" }}
          panelProps={{ className: "itemPanel" }}
        />
      );
    
    return <div>
        <div id="hero">
        <div className="hero-fadeout"></div>
        <div className="hero-overlay"></div>
        <div className="hero-img hero-img-faq">
            <div className="section-title">
                <h2>Preguntas Frecuentes</h2>
            </div>
        </div>
    </div>

    <div className="services-intro">
        <div className="intro-txt">
            <p>Aquí puedes encontrar las preguntas más comunes respondidas rápidamente.</p>
        </div>

        <div className="intro-cta">
            <a href="/#/contact"><button>Pregunta sobre nuestros proyectos</button></a>
        </div>
    </div>

    <div className="faq-inner">

        <div className="faq-accordion">
            <div className="faq-accordion-group">

                <div className="accordion">
                    <Accordion transition transitionTimeout={250}>
                    {faqs.map((faq) => {
                    return (
                    <AccordionItem header={faq.question} className="faq-accordion-menu" key={faq.id}>
                        <p>{faq.answer}</p>
                        <div 
                        className={faq.video === 'null' ? "hs-responsive-embed-youtube hidden" : "hs-responsive-embed-youtube"
                            }>
                        <iframe src={faq.video + '?rel=0&amp;showinfo=0'}></iframe></div>  
                    </AccordionItem>
                     );
                    })} 
                    </Accordion>
                </div>
            </div>
        </div>
    </div>
    </div>;
}

export default Faq;