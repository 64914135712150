import React, {useState, useEffect } from 'react';



function Team() {
    const [teams, setTeams] = useState([]);
    useEffect(() => {
       fetch('https://admin.peam.group/api/team/index')
          .then((response) => response.json())
          .then((data) => {
             console.log(data);
             setTeams(data);
          })
          .catch((err) => {
             console.log(err.message);
          });
    }, []);

    return <div>
            <div id="hero">
        <div className="hero-fadeout"></div>
        <div className="hero-overlay"></div>
        <div className="hero-img hero-img-team">
            <div className="section-title">
                <h2>Meet the Team</h2>
            </div>
        </div>
    </div>

    <div className="services-intro">
        <div className="intro-txt">
            <p>Siempre cuidando y respetando su seguridad financiera. Detonamos sus inversiones de la mejor manera. </p>
        </div>
    </div>

    <div className="team-grid">
        <div className="team-row">
        {teams.map((team) => {
            return (
            <a href={'/#/team/' + team.id} key={team.id}>
                <div className="team-grid-square">
                    <div className="team-ft-img">
                        <div className="tea-ft-text">
                            <h2>{team.name}</h2>
                            <h6>{team.position}</h6>
                        </div>
                    </div>
                    <div className="team-image">
                            <img src={'https://peam.s3.amazonaws.com/'+ team.image} />
                    </div>
                </div>
            </a>
            );
        })} 
        </div>

    </div>
    </div>;
}

export default Team;