import React, {useState, useEffect, useRef} from 'react';
import emailjs from '@emailjs/browser';



function Contact() {
    const [isSubmitted, setIsSubmitted] = useState(false); 
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_96h1spc', 'template_izs8tkb', form.current, 'VyYGovyDrsnGQttOf')
        .then((result) => {
            setIsSubmitted(true);
        }, (error) => {
            console.log(error.text);
        });
    };


    const [service, setDesignation] = useState("");
    const [showOption, setShowOption] = useState(false);

    const [services, setServices] = useState([]);
    useEffect(() => {
       fetch('https://admin.peam.group/api/services/index')
          .then((response) => response.json())
          .then((data) => {
             setServices(data);
          })
          .catch((err) => {
             console.log(err.message);
          });
    }, []);
  

    return <div>
        <div id="hero">
        <div className="hero-fadeout"></div>
        <div className="hero-overlay"></div>
        <div className="hero-img hero-img-contact">
            <div className="section-title">
                <h2>Contacto</h2>
            </div>
        </div>
    </div>

    <div className="services-intro">
        <div className="intro-txt">
            <p>Aquí puedes encontrar las preguntas más comunes respondidas rápidamente, si por alguna razón tu pregunta no está aquí, no dudes en contactárnos.</p>
        </div>
    </div>

    <div className="contact-form">
        <div className="contact-form-inner">
            <form ref={form} onSubmit={sendEmail}>
                <div className="form-row">
                    <label>Nombre Completo</label>
                    <input type="text" name="name" id="name" placeholder="Pablo Pérez García" required/>
                </div>

                <div className="form-row">
                    <label>Teléfono</label>
                    <div className="form-suffix">
                        <span>+52</span>
                        <input type="tel" name="phone" placeholder="442 123 4567" className="phone-input" required/>
                    </div>
                </div>

                <div className="form-row">
                    <label>Correo eletrónico</label>
                    <input type="email" name="email" id="email" placeholder="name@email.com" required/>
                </div>

                <div className="form-row">
                    <label className="label">Motivo de contacto</label>
                        <div className="control">
                            <select
                            className="input"
                            id="service"
                            name="motive"
                            value={service}
                            type="text"
                            onChange={(e) => {
                                setDesignation(e.target.value);
                                if (e.target.value == "servicios") setShowOption(true);
                                else setShowOption(false);
                            }}
                            placeholder="Your motivo"
                            required
                            >
                            <option>Selecciona el motivo</option>
                            <option value="trabajo">Bolsa de trabajo</option>
                            <option value="asesoria">Asesoría</option>
                            <option value="servicios">Servicios</option>
                            <option value="otros">Otros</option>
                            </select>
                        </div>
                </div>
                
                {
                showOption && <div className="form-row">
                    <label className="label">Servicio</label>
                    <div className="control ">
                    <select
                            className="input"
                            id="services"
                            name="services"
                            value={service}
                            type="text"
                            onChange={(e) => setDesignation(e.target.value)}
                            placeholder="Tu servicio"
                            required
                            >
                            {services.map((service) => {
                            return (
                            <option key={service.id} value={service.title}>{service.title}</option>
                            );
                            })} 
                            </select>
                    </div>
                </div>
                }

                <div className="form-row">
                   <div> <label>Mensaje</label></div>
                    <textarea id="message" name="message" className='color-white' required></textarea>
                </div>

                <div className="form-row">
                    <label>¿Cómo te gustaría que nos comunicaramos contigo?</label>
                    <select multiple className="form-control" id="sel2" name="contact">
                        <option value="whatsapp">WhatsApp</option>
                        <option value="llamada">Llamada</option>
                        <option value="email">Correo Electrónico</option>
                    </select>
                </div>

                <div className="form-row">
                    <label className="privacy-container">Acepto los términos y condiciones, y a el aviso de privacidad.
                        <input type="checkbox" required/><span className="checkmark"></span>
                    </label>
                </div>

                <div className="form-row">
                    <input type="submit" id='input-submit' value="Mandar mensaje" />
                </div>

                {isSubmitted && <div className="success-message"><p className='successmessage'>¡Gracias! Se ha enviado tu mensaje correctamente.</p></div>}
                
            </form>
        </div>
    </div>

    </div>;
}

export default Contact;