import React from 'react';
import { Link } from 'react-router-dom';
import ServiceTabs from './Services/ServiceTabs'


function Services() {
    return <div>

        <div id="hero">
                <div className="hero-fadeout"></div>
                <div className="hero-overlay"></div>
                <div className="hero-img hero-img-services">
                    <div className="section-title">
                        <h2>Servicios</h2>
                    </div>
                </div>
            </div>

        <div className="services-intro">
        <div className="intro-txt">
            <p>Aquí puedes encontrar las preguntas más comunes respondidas rápidamente.</p>
        </div>

        <div className="intro-cta">
            <Link to="/contacto"><button>Pregunta sobre nuestros proyectos</button></Link>
        </div>
        
        <div className="sevicesWrapp">
            <ServiceTabs />
        </div>
    </div>
    </div>;
}

export default Services;