import React from 'react';
import { useState, useEffect } from 'react';


function Careers() {
    const [careers, setCareers] = useState([]);
    const [searchTitle, setSearchTitle] = useState("");
    useEffect(() => {
       fetch('https://admin.peam.group/api/careers/index')
          .then((response) => response.json())
          .then((data) => {
             console.log(data);
             setCareers(data);
          })
          .catch((err) => {
             console.log(err.message);
          });
    }, []);

    return <div>
        <div id="hero">
            <div class="hero-fadeout"></div>
            <div class="hero-overlay"></div>

            <div class="hero-img hero-img-careers">
                <div class="section-title">
                    <h2>Bolsa de Trabajo</h2>
                </div>
            </div>
        </div>

        <div class="bolsa-intro">
            <div class="services-tab-btn">
              </div>
            <div class="bolsa-search">
                <input 
                    type="text" 
                    placeholder="Buscar trabajo..."
                    onChange={(e) => setSearchTitle(e.target.value)} 
                    />
            </div>

            <div class="bolsa-inner">

                <div class="bolsa-filter">
                {careers.filter((value) => {
                    if(searchTitle === "") {
                        return value
                    } else if(value.title.toLowerCase().includes(searchTitle.toLocaleLowerCase())
                      ) {
                            return value;
                        }
                    })
                .map((career) => {
                    return (
                    <div class="job-card" key={career.id}>
                        <div class="job-category">
                            <p>{career.category}</p>
                        </div>

                        <div class="job-title">
                            <a href={'/#/careers/' + career.id}>
                                <p>{career.title}</p>
                            </a>
                            <span>{career.location}</span>
                        </div>

                        <div class="job-type">
                            <a href={'/#/careers/' + career.id}>
                                <p>{career.type}</p>
                            </a>
                            <img src="/imgs/arrow-right.svg"/>
                        </div>
                    </div>
                      );
                    })}
            </div>
        </div>
    </div>

    </div>;
}

export default Careers;