import React, { useState, useMemo, useEffect } from 'react';
import { Accordion, AccordionItem as Item } from '@szhsin/react-accordion';
import chevronDown from './chevron-down.svg'
import Map, { Marker,Popup,NavigationControl, FullscreenControl, ScaleControl, GeolocateControl } from 'react-map-gl';
import { Link } from 'react-router-dom';

  
import Pin from './Pin';

import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

 
mapboxgl.accessToken = 'pk.eyJ1IjoibGVpbGFhdCIsImEiOiJjbGoxNTc3azIwMHI5M2dvZWFhbGtxaTA4In0.fhfdwfPhi3pDKirjbm_PeA';


function FilterMap() {

    const [popupInfo, setPopupInfo] = useState(null);
    const [selectedCategories, setSelectedCategories] = useState([]);

    const [projects, setProjects] = useState([]);
    useEffect(() => {
       fetch('https://admin.peam.group/api/projects/index')
          .then((response) => response.json())
          .then((data) => {
             setProjects(data);
          })
          .catch((err) => {
             console.log(err.message);
          });
    }, []);
    
    const pins = useMemo(
      () =>
    projects
      .filter((project) =>
        selectedCategories.length === 0 || selectedCategories.includes(project.category)
      )
      .map((project) => (
        <Marker
          key={`marker-${project.id}`}
          longitude={project.longitude}
          latitude={project.latitude}
          anchor="bottom"
          onClick={(e) => {
            e.originalEvent.stopPropagation();
            setPopupInfo(project);
          }}
        >
          {project.category === 'Comercial' && <Pin size={20} fill="#b2eb39" />} {/* Green */}
          {project.category === 'Residencial' && <Pin size={20} fill="#4862bc" />} {/* Blue */}
          {project.category === 'Industrial' && <Pin size={20} fill="#ebaf21" />} {/* Yellow */}
        </Marker>
      )),
      [projects, selectedCategories]
    );

    const handleCategoryChange = (category) => {
      if (selectedCategories.includes(category)) {
        setSelectedCategories(selectedCategories.filter((c) => c !== category));
      } else {
        setSelectedCategories([...selectedCategories, category]);
      }
    };

    const [toggleState, setToggleState] = useState(1);
    const toggleTab = (index) => {
      setToggleState(index);
    };

    const AccordionItem = ({ header, ...rest }) => (
        <Item
          {...rest}
          header={
            <>
              {header}
              <img className="chevron"src={chevronDown} alt="Chevron Down" />
            </>
          }
        
          buttonProps={{
            className: ({ isEnter }) =>
              `pitemBtn ${isEnter && "pitemBtnExpanded"}`
          }}
          contentProps={{ className: "itemContent" }}
          panelProps={{ className: "itemPanel" }}
        />
      );

    return (<div>
        <div className="map-filter">
            <div className="map-filter-checkbox">
                <div><li><strong>Filtrar por tipo de proyecto</strong></li></div>
                
                {/* <li><img src="../../imgs/circle-green.svg" />Comercial</li>
                <li><img src="../../imgs/circle-blue.svg" />Residencial</li>
                <li><img src="../../imgs/circle-yellow.svg" />Industrial</li> */}
                <div>
                  <label>
                    <input
                        type="checkbox"
                        value="Show All"
                        onChange={() => setSelectedCategories([])} // Clear selected categories
                        checked={selectedCategories.length === 0}
                      />
                      Todos
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      value="Comercial"
                      onChange={(e) => handleCategoryChange(e.target.value)}
                      checked={selectedCategories.includes('Comercial')}
                    />
                    Comercial <img src="../../imgs/circle-green.svg" />
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      value="Residencial"
                      onChange={(e) => handleCategoryChange(e.target.value)}
                      checked={selectedCategories.includes('Residencial')}
                    />
                    Residencial <img src="../../imgs/circle-blue.svg" />
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      value="Industrial"
                      onChange={(e) => handleCategoryChange(e.target.value)}
                      checked={selectedCategories.includes('Industrial')}
                    />
                    Industrial <img src="../../imgs/circle-yellow.svg" />
                  </label>
                </div>
            </div>

            <div className="map-filter-filters">
                {/* <div className="map-filter-product">
            
                    <Accordion transition transitionTimeout={250}>
            
                    <AccordionItem header="Tipo de producto" className="project-dropdown">
                      <div className="map-filter-checkbox">
                        <label>
                          <input
                            type="checkbox"
                            value="Comercial"
                            onChange={(e) => handleCategoryChange(e.target.value)}
                            checked={selectedCategories.includes('Comercial')}
                          />
                          Comercial
                        </label>
                        <label>
                          <input
                            type="checkbox"
                            value="Residencial"
                            onChange={(e) => handleCategoryChange(e.target.value)}
                            checked={selectedCategories.includes('Residencial')}
                          />
                          Residencial
                        </label>
                        <label>
                          <input
                            type="checkbox"
                            value="Industrial"
                            onChange={(e) => handleCategoryChange(e.target.value)}
                            checked={selectedCategories.includes('Industrial')}
                          />
                          Industrial
                        </label>
                      </div>
          
                    </AccordionItem>
                   
                    </Accordion>

                </div> */}

                <div className="map-filter-price">
                    <select>
                        <option>Rango de precio</option>
                        <option>$00 - $000</option>
                        <option>$000 - $0000</option>
                    </select>   
                </div>
            </div>
        </div>

        <div className="map">
        <>
              <Map
                initialViewState={{
                  latitude: 20.58717977179108,
                  longitude: -100.38778631517337,
                  zoom: 7,
                  bearing: 0,
                  pitch: 0
                }}
                mapStyle="mapbox://styles/mapbox/dark-v9"
                mapboxAccessToken={mapboxgl.accessToken}
              >
                <GeolocateControl position="top-left" />
                <FullscreenControl position="top-left" />
                <NavigationControl position="top-left" />
                <ScaleControl />

                {pins}

                {popupInfo && (
                <Popup
                    anchor="top"
                    longitude={Number(popupInfo.longitude)}
                    latitude={Number(popupInfo.latitude)}
                    onClose={() => setPopupInfo(null)}
                    closeOnClick={false}
                    captureClick={true}
                    
                  >
                  <div className="popup-content-wrapp">
                    <div className="popup-txt">
                      <h3>{popupInfo.title}</h3>
                      <p>{popupInfo.address}</p>
                    </div>
                    <div className='popup-img'>
                      <img width="100px" hegith="auto" src={'https://peam.s3.amazonaws.com/' + popupInfo.ftimage} />
                    </div>
                  </div>
                  <div className='center-align'>                  
                  <Link to={'/projects/' + popupInfo.id}><button className="popup">Ver Proyecto</button></Link>
                  </div>
                </Popup>
                )}
              </Map>

          </>
        </div>

        <div></div>
        <div className="trabajos-grid">
            <div className="trabajos-grid-inner">
                {projects
                 .filter((project) =>
                 selectedCategories.length === 0 || selectedCategories.includes(project.category)
                )
                .map((project) => {
                return (
                            <div className="trabajos-single-wrap">
                                <div className="trabajos-item" key={project.id}>
                                    <div className="trabajo-grid-img" style={{ backgroundImage: `url(${'https://peam.s3.amazonaws.com/' + project.ftimage})` }}>
                                        <div className="main-slider-txt">
                                            <h4 className="psingle">{project.title}</h4>
                                            <h6 className="psingle">{project.address}</h6>
                                            <button className="terciario"><Link to={'/projects/' + project.id}>Ver Proyecto</Link></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    );
                })} 
            </div>
        </div>
    </div>)
}

export default FilterMap;