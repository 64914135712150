import { useState, useEffect } from 'react';

export default function TabContainer() {


  const [services, setServices] = useState([]);
  useEffect(() => {
     fetch('https://admin.peam.group/api/services/index')
        .then((response) => response.json())
        .then((data) => {
           console.log(data);
           setServices(data);
        })
        .catch((err) => {
           console.log(err.message);
        });
  }, []);

  const defaultActiveTab = services.length > 0 ? services[0].id : 21;
  const [toggleState, setToggleState] = useState(defaultActiveTab);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (<div>


        <div className="services-tabs">

          <div className="services-tab-btn">
          {services.map((service) => {
            return (
              <button 
                key={service.id}
                className={toggleState === service.id ? "tabs services-tabs-active" : "tabs"}
                onClick={() => toggleTab(service.id)}
              >
                {service.title}
              </button>
                );
              })} 
          </div>

        {services.map((service) => {
          
                return (
          <div 
            id="tab" 
            className={toggleState === service.id ? "services-tabcontent active-content" : "services-tabcontent"}>
            <div className="service-tab-inner">
              <h5>{service.title}</h5>
              <p dangerouslySetInnerHTML={{ __html: service.desc }} />
              <div 
              className={service.video === 'null' ? "hs-responsive-embed-youtube hidden" : "hs-responsive-embed-youtube"
                }>
                <iframe src={service.video + '?rel=0&amp;showinfo=0'}></iframe></div> 

             
            </div>
          </div>
        );
        })} 
        </div>


  </div>
  );
}

