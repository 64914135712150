import {HashRouter as Router, Route, Routes} from 'react-router-dom';
import Navbar from './components/Navbar';
import Menu from './components/Menu';
import Home from './views/Home';
import Services from './views/Services';
import Projects from './views/Projects';
import Contact from './views/Contact';
import Footer from './components/Footer';
import Team from './views/Team/Team';
import Faq from './views/Faq/Faq';
import Careers from './views/Careers/Careers';
import Single from './views/Team/Single';
import Job from './views/Careers/Job';
import Psingle from './views/Project/Psingle'

export default function App() {
	
  	return (
    	<Router>
      		<div>
			{/* {window.location.pathname !== '/auth/login'  &&  <Navbar />}      	 */}
	
			<Menu/>
        		<Routes>
          			<Route path="/services" element={<Services />} />
					<Route path="/projects" element={<Projects />} />
					<Route path="/contact" element={<Contact />} />
          			<Route path="/" element={<Home />} />
					<Route path="/team" element={<Team />} />
					<Route path="/team/:id" element={<Single />} />
					<Route path="/faq" element={<Faq />} />
					<Route path="/careers" element={<Careers />} />
					<Route path="/careers/:id" element={<Job />} />
					<Route path="/quote" element={<Contact />} />
					<Route path="/asesoria" element={<Contact />} />
					<Route path="/projects/:id" element={<Psingle />} />
        		</Routes>
				{/* {window.location.pathname !== '/auth/login' && <Footer />}      		 */}
			</div>
    	</Router>
  	);
	
	
}

